import * as React from "react";
import Map from "./Map";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";

import videsPieejamibaLV from "../files/Vides pieejamība centrā RŪRE- LV.pdf";
import videsPieejamibaEN from "../files/Vides pieejamība centrā RŪRE- ENG.pdf";
import videsPieejamibaRU from "../files/Vides pieejamība centrā RŪRE RUS.pdf";
import registracijasIzzina from "../files/Magnum Social&Medical care, SIA_e-izziņa.pdf";
import infoLV from "../files/Info pacientiem_klientiem- LV.pdf";
import infoEN from "../files/Info pacientiem_klientiem- ENG.pdf";
import infoRU from "../files/Info pacientiem_klientiem_RUS.pdf";
import dienasKartibaLV from "../files/Dienas kārtība- LV.pdf";
import dienasKartibaEN from "../files/Dienas kārtība ENG.pdf";
import dienasKartibaRU from "../files/Dienas kārtība RUS.pdf";
import kartibasNoteikumiLV from "../files/SOCIĀLĀ PAKALPOJUMA SNIEGŠANAS IEKŠĒJĀS KĀRTĪBAS NOTEIKUMI- LV.pdf";
import kartibasNoteikumiEN from "../files/SOCIĀLĀ PAKALPOJUMA SNIEGŠANAS IEKŠĒJĀS KĀRTĪBAS NOTEIKUMI- ENG.pdf";
import kartibasNoteikumiRU from "../files/SOCIĀLĀ PAKALPOJUMA SNIEGŠANAS IEKŠĒJĀS KĀRTĪBAS NOTEIKUMI RUS.pdf";

import "./BottomCommonSection.css";
import { graphql } from "gatsby";

function getDocs(currentLanguage?: string) {
    switch (currentLanguage) {
        case "lv":
            return {
                videsPieejamiba: videsPieejamibaLV,
                dienasKartiba: dienasKartibaLV,
                kartibasNoteikumi: kartibasNoteikumiLV,
                info: infoLV,
            };
        case "en":
            return {
                videsPieejamiba: videsPieejamibaEN,
                dienasKartiba: dienasKartibaEN,
                kartibasNoteikumi: kartibasNoteikumiEN,
                info: infoEN,
            };
        case "ru":
            return {
                videsPieejamiba: videsPieejamibaRU,
                dienasKartiba: dienasKartibaRU,
                kartibasNoteikumi: kartibasNoteikumiRU,
                info: infoRU,
            };
        default:
            return {
                videsPieejamiba: videsPieejamibaLV,
                dienasKartiba: dienasKartibaLV,
                kartibasNoteikumi: kartibasNoteikumiLV,
                info: infoLV,
            };
    }
}

export default function BottomCommonSection() {
	const { t } = useTranslation();
    const { i18n } = useI18next();
    const currentLanguage = i18n.resolvedLanguage;

    const {
        videsPieejamiba,
        dienasKartiba,
        kartibasNoteikumi,
        info,
    } = getDocs(currentLanguage);

    return (
        <div className="contentBlock noPadding contactInfo">
            <div className="mapContainer">
                <Map />
            </div>
            <div className="contactInfoRight">
                <h2 className="contactInfoRightTitle">
                    <a href="mailto:info@rure.lv">Info@rure.lv</a><br />
                    <a href="tel:+37129396285">+371 293 962 85</a>
                </h2>
                <div className="contactInfoRightInfo">
                    <div><Trans i18nKey="workTime" components={{ break: <br /> }} /></div>
                    <div><Trans i18nKey="address" components={{ break: <br /> }} /></div>
                </div>
                <div className="downloads">
                    <a href={kartibasNoteikumi} download><h3>{t("innerRules")}</h3></a>
                    <a href={videsPieejamiba} download><h3 style={{ width: "auto" }}>{t("envAvailability")}</h3></a>
                    <a href={registracijasIzzina} download><h3 style={{ width: "auto" }}>{t("registrationReference")}</h3></a>
                    <a href={info} download><h3 style={{ width: "auto" }}>{t("information")}</h3></a>
                    <a href={dienasKartiba} download><h3 style={{ width: "auto" }}>{t("agenda")}</h3></a>
                </div>
            </div>
        </div>
    );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["BottomCommonSection", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
